import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";
import Homepage from "./Homepage";
import ServicePage from "./ServicePage";
import QuotePage from "./QuotePage";
import ContactPage from "./ContactPage"; // Uncomment if exists
import AboutPage from "./AboutPage"; // Uncomment if exists
import NotFound from "./NotFound"; // Optional 404 page component
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import FAQ from "./FAQ";


function App() {
  return (
    <>
    
    <Router>
      <div className="App">
        {/* Navbar */}
        <Navbar />

        {/* Main Routes */}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/quote" element={<QuotePage />} />
          <Route path="/contact" element={<ContactPage />} /> {/* Uncomment if exists */}
          <Route path="/about" element={<AboutPage />} /> {/* Uncomment if exists */}
          <Route path="*" element={<NotFound />} /> {/* Catch-all 404 route */}
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/faq" element={<FAQ />} />

        </Routes>
      </div>
    </Router>
    </>
  );
}

export default App;
