import React from "react";
import "./ServicePage.css";

const services = [

  {
    title: "Local Residential Moves",
    image: "/moving.png",
    description: "From apartments to estates, we offer personalized moving solutions within your city, ensuring a smooth and efficient relocation.",
    link: "https://sophisticatedmoves.zohobookings.com/#/Local"
  },
  {
    
    title: " Long-Distance Moves",
    image: "/LongDistance.png",
    description: "Expertly crafted for seamless transitions, no matter the miles. We ensure the safe transport of your belongings with precision and care.",
    link: "https://sophisticatedmoves.zohobookings.com/#/LongDistance"
    
  },
  
  {
    title: "Two Men | 2-Hour Service",
    image: "/4.png",
    description: "Need flexibility? Our hourly service provides top-tier support tailored to your schedule and specific needs.",
    link: "https://sophisticatedmoves.zohobookings.com/#/TwoMen"
  },
  {
    title: "Office and Commercial Moves",
    image: "/commericalmoves.png",
    description: "Minimize downtime with our tailored commercial move services, designed to relocate your business seamlessly and efficiently.",
    link: "https://sophisticatedmoves.zohobookings.com/#/Commercial"
  },
  
  {
    title: "Compact Move Solutions |  | Small Moves",
    image: "/smallmoves.png",
    description: "For those compact relocations or single-item transports, we provide the same sophisticated care and expertise as our larger moves.",
    link: "https://sophisticatedmoves.zohobookings.com/#/Compact"
  },
  {
    title: "Rapid Moves | Urgent Moves",
    image: "/rapidmoves.png",
    description: "For those urgent moves, we offer swift and efficient solutions, ensuring your belongings are safely transported in a matter of hours.",
    link: "https://sophisticatedmoves.zohobookings.com/#/Rapid"

  },
  {
    title: "Packing & Unpacking",
    image: "/packing.png",
    description: "Experience the convenience of our meticulous packing and unpacking services, designed to save you time and effort.",
    link: "https://sophisticatedmoves.zohobookings.com/#/packing-unpacking"
  },
  {
    title: "Assembly | Disassembly",
    image: "/assembly.png",
    description: "From furniture to specialized equipment, we provide expert assembly and disassembly to simplify your move.",
    link: "https://sophisticatedmoves.zohobookings.com/#/assembly-disassembly"
  },
];

const ServicePage = () => {
  return (
    <>
    <div className="main-container">
    <div className="services-page">
      <img src="./SMNYLOGO1.png" alt="services-img" className="services-img" />
      <header className="services-header">
        <h1>🌟 Our Commitment to Excellence</h1>
        <p></p>
        <p>
          At Sophisticated Moves, we handle the heavy lifting so you can focus
          on embracing your new beginning with confidence and style. <br></br>Let us
          transform your move into an experience that is as effortless as it is
          refined. We don’t just move belongings; we move lives, memories, and aspirations. <br>
          </br> 
        </p>

      </header>
      <header className="services-h1"> <h1> Our Services</h1>  </header>

      <div className="services-container">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <img src={service.image} alt={service.title} className="service-image" />
            <h2>{service.title}</h2>
            <p>{service.description}</p>
            
            <a href={service.link} target="_blank" rel="noopener noreferrer" >
            <button>Book Now</button>
            </a>
          </div>
        ))}
      </div>

      <div className="details-container">
  {/* Image Section (Vertical Alignment) */}
  <div className="details-images">
    <img src="./3.png" alt="services-img" className="detail-img" />
    <img src="./12.png" alt="services-img" className="detail-img" />
    <img src="./SMNY12.png" alt="services-img" className="detail-img" />
  </div>

  {/* Content Section */}
  <div className="details-content">
    <h2> Why Choose Sophisticated Moves?</h2>

    <p>
      <h5>☑️ Customer-First Approach</h5>
      We listen, plan, and execute with your needs in mind. Our team treats every move as if it were their own, ensuring personalized service tailored to your unique requirements.
    </p>

    <p>
      <h5>☑️ Professional & Highly-Trained Movers</h5>
      Our team is background-checked, trained, and experienced in handling moves of all sizes and complexities. We take pride in our professionalism, punctuality, and attention to detail.
    </p>

    <p>
      <h5>☑️ Premium Packing & Protection</h5>
      We use top-quality packing materials to safeguard your valuables. Whether it's fragile glassware, antiques, or bulky furniture, we ensure maximum protection for your belongings.
    </p>

    <p>
      <h5>☑️ Transparent Pricing & No Hidden Fees</h5>
      We provide honest, upfront pricing with no surprises. Our goal is to deliver the best value with high-quality moving services at competitive rates.
    </p>

    <p>
      <h5>☑️ Punctuality & Reliability</h5>
      We respect your time. Our team is committed to on-time arrivals and efficient service so that your move stays on schedule.
    </p>

    <p>
      <h5>☑️ Stress-Free Moving Experience</h5>
      We handle everything from planning to unpacking, so you can focus on settling into your new home or office. Our goal is to make moving a stress-free and enjoyable process.
    </p>

    <p>
      <h6>At Sophisticated Moves, we don't just move boxes—we move lives with care, precision, and a commitment to excellence.</h6>
      <br />
      💬 Have questions? Need a quote? We’d love to assist you in making your move a Sophisticated one! 🚛✨
      <a href={"/quote"} target="_blank" rel="noopener noreferrer" className="quote-link" >
            <button>Click Here</button>
            </a>
    </p>
  </div>
</div>



        </div>
        </div>
        </>
  
    
  );
};

export default ServicePage;
