import React from "react";
import { useNavigate } from "react-router-dom";
import "./Homepage.css";

const Homepage = () => {
  const navigate = useNavigate();

  const handleGetQuoteClick = () => {
    navigate("/quote"); // Redirect to the QuotePage
  };
  return (
    <>


    <div className="homepage-container">
      {/* Hero Section */}

      
      <header className="hero-section">
      {/* <div className="promo-banner">
  <p>
    <strong>Get 10% Off Your First Move on Us!</strong> Experience the excellence of Sophisticated Moves!
  </p>
</div> */}
  <img src="./SMNY1.png" alt="homepage-img" className="homepage-img" />
  <div className="hero-content">
    <h1>Welcome to Sophisticated Moves</h1>
    <p>Redefining the moving experience with luxury, precision, and care.</p>
    <button className="cta-button" onClick={handleGetQuoteClick}>
      Get Your Quote Now
    </button>
  </div>
</header>


      {/* About Us Section */}
      <section className="about-section">
        <h2>About Us</h2>
        <p>
          Sophisticated Moves is not just a moving company; we are your trusted
          partner in delivering a luxurious and stress-free relocation
          experience.<br>
          </br> With a foundation built on professionalism and elegance,
          we cater to residential, commercial, and specialty moves, tailoring
          our services to meet your unique needs.
        </p>
        <p>
          Whether you're moving across town or across the country, our expert
          team is equipped to handle every aspect of your transition with
          precision and care.
        </p>
      </section>

      {/* Services Section */}
      <section className="services-section">
        <h2>Our Services</h2>
        <h5> Book a call now to learn more about our services</h5>
        <h6>At Sophisticated Moves, we handle the heavy lifting so you can focus on embracing your new beginning with confidence and style.</h6>

        <div className="services-list">
          <div></div>
          <div className="service-item">
            <h3>Local Residential Moves</h3>
            <p>
              Personalized moving solutions within your city, ensuring smooth
              relocations.
            </p>
            <a
              href="https://sophisticatedmoves.zohobookings.com/#/Local"
              target="_blank"
              rel="noopener noreferrer"
              className="service-link"
            >
              Book Now
            </a>
          </div>
          <div className="service-item">
            <h3>Hourly Moving Assistance</h3>
            <p>
              Flexible hourly support tailored to your schedule and specific
              needs.
            </p>
            <a
              href="https://sophisticatedmoves.zohobookings.com/#/Hourly"
              target="_blank"
              rel="noopener noreferrer"
              className="service-link"
            >
              Book Now
            </a>
          </div>
          <div className="service-item">
            <h3>Commercial Moves</h3>
            <p>
              Minimize downtime with tailored commercial move services.
            </p>
            <a
              href="https://sophisticatedmoves.zohobookings.com/#/commercialquote"
              target="_blank"
              rel="noopener noreferrer"
              className="service-link"
            >
              Book Now
            </a>
          </div>
          <div className="service-item">
            <h3>Small Moves</h3>
            <p>
              Compact relocations or single-item transports with sophisticated
              care.
            </p>
            <a
              href="https://sophisticatedmoves.zohobookings.com/#/smallmoves"
              target="_blank"
              rel="noopener noreferrer"
              className="service-link"
            >
              Book Now
            </a>
          </div>
          <div className="service-item">
            <h3>Long-Distance Moves</h3>
            <p>
              Expertly crafted for seamless transitions, no matter the miles.
            </p>
            <a
              href="https://sophisticatedmoves.zohobookings.com/#/long-distance"
              target="_blank"
              rel="noopener noreferrer"
              className="service-link"
            >
              Book Now
            </a>
          </div>
          <div className="service-item">
            <h3>Priority Relocation Services</h3>
            <p>
              Elevate your move with our top-tier relocation services. Need Movers NOW!! We have urgent movers ready to serve you.
            </p>
            <a
              href="https://sophisticatedmoves.zohobookings.com/#/PRIORITY"
              target="_blank"
              rel="noopener noreferrer"
              className="service-link"
            >
              Book Now
            </a>

          </div>
          <div className="service-item">
            <h3>Packing & Unpacking</h3>
            <p>
              Meticulous packing and unpacking services to save you time and
              effort.
            </p>
            <a
              href="https://sophisticatedmoves.zohobookings.com/#/packing-unpackung"
              target="_blank"
              rel="noopener noreferrer"
              className="service-link"
            >
              Book Now
            </a>
          </div>
          <div className="service-item">
            <h3>Assembly | Disassembly</h3>
            <p>
              Expert assembly and disassembly for furniture and specialized
              equipment.
            </p>
            <a
              href="https://sophisticatedmoves.zohobookings.com/#/assembly-disassemble"
              target="_blank"
              rel="noopener noreferrer"
              className="service-link"
            >
              Book Now
            </a>
          </div>
          
        </div>
      </section>

      {/* Chatbot Section */}
      <section className="chatbot-section">
        <h2>Streamline Your Move with Our Chatbot</h2>
        
        <p>
          Get a personalized moving quote in minutes. Our intelligent chatbot
          ensures transparency and convenience from the start.
        </p>
        <button className="cta-button" onClick={handleGetQuoteClick}>Start Chat</button>
      </section>

      {/* Testimonials Section */}
<section className="testimonials-section">
  <h2>What Our Clients Say</h2>
  <p>
    Don’t just take our word for it. See what our clients have to say about their experiences with Sophisticated Moves!
  </p>

  <div className="reviews-container">
  <div className="media-wrapper">
    {/* Google Review Video */}
    <video 
      className="review-video" 
      controls 
      loop 
      muted 
      playsInline
      autoPlay
    >
      <source src="./REVIEWS.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    
    {/* Google Review Image */}
    <img 
      src="./2.jpg" 
      alt="Google Reviews" 
      className="review-image" 
    />
  </div>

  <div className="grateful-section">
    <h3>We’re Grateful for You!</h3>
    <p>
      At <strong>Sophisticated Moves</strong>, every move is a story, every client a new friend. Your trust is what drives us to provide the most seamless, sophisticated, and enjoyable moving experience. 
    </p>
    <p>
      Our clients are more than customers—they’re part of the <strong>Sophisticated Family</strong>. Thank you for letting us turn your transitions into unforgettable milestones. Together, we make moving a moment to celebrate.
    </p>
    <p>
      If you’ve moved with us recently, don’t forget to leave a review and share the love. We’re always here to make your next move even better!
    </p>

  </div>
  {/* Google Review Link */}
  <div className="review-link">
    <p>Check out more reviews on Google!</p>
    <a 
      href="https://g.page/r/Ce-H_30q3D1gEAE/review" 
      target="_blank" 
      rel="noopener noreferrer"
      className="google-link"
    >
      View Reviews
    </a>
  </div>
</div>


</section>

    </div>
    <footer className="footer">
  <p>&copy; 2025 Sophisticated Moves. All rights reserved.</p>
  <div className="footer-links">
    <a href="/contact">Contact</a>
    <a href="/privacy">Privacy Policy</a>
    <a href="/terms">Terms & Conditions</a>
    <a href="/faq">FAQ</a>
    <a href="/about">About</a>
  </div>
</footer>

    </>
  );
};

export default Homepage;
