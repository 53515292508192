import React, { useState } from "react";
import { FaInstagram, FaFacebookF, FaYelp } from "react-icons/fa"; // Icons
import "./ContactPage.css";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [status, setStatus] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch("http://127.0.0.1:5000/send-contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (result.status === "success") {
        setStatus("✅ Message sent successfully!");
        setFormData({ name: "", email: "", phone: "", message: "" });
      } else {
        setStatus("❌ There was an error. Please try again.");
      }
    } catch (error) {
      setStatus("❌ Error sending message. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
    <div className="contact-page">
    <div className="contact-container">
      {/* Left Section: Contact Info */}
      <div className="contact-info">
        <h2>📞 Get in Touch</h2>
        <p>Have a question? We’re here to help! Reach out to us for any inquiries.</p>

        <div className="contact-details">
  <h3>📍 Contact Information</h3>
  
  <div className="contact-item">
    <i className="fas fa-map-marker-alt"></i>
    <p>Brooklyn, NY</p>
  </div>

  <div className="contact-item">
    <i className="fas fa-envelope"></i>
    <a href="mailto:admin@sophisticatedmoves.com">admin@sophisticatedmoves.com</a>
  </div>

  <div className="contact-item">
    <i className="fas fa-phone"></i>
    <a href="tel:+19295414886">(929) 541-4886</a>
  </div>

  <div className="contact-item">
    <i className="fas fa-clock"></i>
    <p>Mon-Sun: 8 AM - 9 PM</p>
  </div>
</div>


        {/* Social Media Links */}
        <div className="social-links">
          <h3>📲 Follow Us</h3>
          <a href="https://www.instagram.com/sophisticatedmoves" target="_blank" rel="noopener noreferrer">
            <FaInstagram /> Instagram
          </a>
          <a href="https://www.facebook.com/profile.php?id=61571946826257" target="_blank" rel="noopener noreferrer">
            <FaFacebookF /> Facebook
          </a>
          <a href="https://www.yelp.com/biz/sophisticated-moves-brooklyn-2" target="_blank" rel="noopener noreferrer">
            <FaYelp /> Yelp
          </a>
        </div>
      </div>

      {/* Right Section: Contact Form */}
      <div className="contact-form">
        <h2>✉️ Send a Message</h2>
        <p>We’d love to hear from you! Fill out the form and we’ll get back to you soon.</p>

        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input type="text" name="name" value={formData.name} onChange={handleChange} required />
          </label>

          <label>
            Email:
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          </label>

          <label>
            Phone:
            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
          </label>

          <label>
            Message:
            <textarea name="message" value={formData.message} onChange={handleChange} required />
          </label>

          {/* ✅ FIX: Place Button Inside Form */}
          <button type="submit" className="submit-btn" disabled={isSubmitting}>
            {isSubmitting ? "Sending..." : "Send Message"}
          </button>
        </form>

        {status && <p className="status-message">{status}</p>}
      </div>
    </div>
    </div>
    <footer className="footer">
    <div className="footer-links">
      <a href="/contact">Contact</a>
      <a href="/privacy">Privacy Policy</a>
      <a href="/terms">Terms & Conditions</a>
      <a href="/faq">FAQ</a>
      <a href="/about">About</a>
    </div>
  </footer>
  </>
  );
};

export default ContactPage;
