import React from "react";
import "./TermsAndConditions.css";

const PrivacyPolicy = () => {
    return (
    <div className="privacy-container">
        <h1>🔒 Privacy Policy</h1>
        <p>Effective Date: January 2025</p>

        <section>
        <h2>1. Information We Collect</h2>
        <p>
            - When you book a move with us, we collect your name, phone number, email, and moving details. <br />
            - Payment details are securely processed via our third-party payment provider. <br />
        </p>
        </section>

        <section>
        <h2>2. How We Use Your Information</h2>
        <p>
            - To provide and manage our moving services. <br />
            - To communicate important updates regarding your move. <br />
            - To improve customer experience through feedback and reviews. <br />
        </p>
        </section>

        <section>
        <h2>3. Data Security</h2>
        <p>
            - We implement advanced security measures to protect your personal data. <br />
            - We do not store credit card information on our servers. <br />
            - Only authorized personnel have access to your personal data. <br />
        </p>
        </section>

        <section>
        <h2>4. Third-Party Sharing</h2>
        <p>
            - We do not sell, trade, or rent your personal information to third parties. <br />
            - We may share necessary data with trusted service providers for operational purposes. <br />
        </p>
        </section>

        <section>
        <h2>5. Your Rights</h2>
        <p>
            - You have the right to request access to, update, or delete your personal information. <br />
            - To exercise your rights, contact us at: <a href="mailto:admin@sophisticatedmoves.com">admin@sophisticatedmoves.com</a>
        </p>
        </section>

        <p>
        By using our services, you agree to our Privacy Policy. We may update this policy periodically.
        </p>
    </div>
    );
};

export default PrivacyPolicy;
