import React from "react";
import "./AboutPage.css";

const AboutPage = () => {
  return (
    <>
    <div className="about-container">
      {/* Hero Section */}
      <div className="hero-section">
        <h2 className="fade-in">
          "Redefining the moving experience with precision, care, and reliability."
        </h2>
      </div>

      {/* Our Story */}
      <div className="content-section slide-in">
        <h2>📜 Our Story</h2>
        <p>
          Founded in 2023, Sophisticated Moves was born with a vision—to create a
          seamless, stress-free, and highly professional moving experience.
          Through dedication and exceptional service, we have built a reputation
          for moving not just boxes, but lives with care and precision.
        </p>
      </div>

      {/* Why Choose Us */}
      <div className="why-choose-us slide-in">
        <h2>⭐ Why Choose Us?</h2>
        <div className="grid-container">
          <div className="grid-item">
            <h3>✅ Experienced & Trained Team</h3>
            <p>Our movers are background-checked, skilled, and professional.</p>
          </div>
          <div className="grid-item">
            <h3>✅ Transparent & Affordable Pricing</h3>
            <p>No hidden fees, just straightforward and competitive pricing.</p>
          </div>
          <div className="grid-item">
            <h3>✅ Comprehensive Moving Services</h3>
            <p>From packing to setup, we cover it all.</p>
          </div>
          <div className="grid-item">
            <h3>✅ Customer Satisfaction First</h3>
            <p>We move with care and treat your belongings like our own.</p>
          </div>
        </div>
      </div>

      {/* Our Values */}
      <div className="values-section fade-in">
        <h2>💎 Our Core Values</h2>
        <div className="values-grid">
          <div className="value-card">
            <h3>🏆 Integrity</h3>
            <p>Honesty and trust are the foundation of our service.</p>
          </div>
          <div className="value-card">
            <h3>🚛 Reliability</h3>
            <p>Always on time, always prepared.</p>
          </div>
          <div className="value-card">
            <h3>🤝 Customer Commitment</h3>
            <p>Your satisfaction is our top priority.</p>
          </div>
          <div className="value-card">
            <h3>🔧 Attention to Detail</h3>
            <p>Every move is carefully planned and executed.</p>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="cta-section fade-in">
        <h2>📞 Get in Touch Today!</h2>
        <p>Experience a stress-free move with **Sophisticated Moves**.</p>
        <a href="/contact">
          <button className="contact-btn">Contact Us</button>
        </a>
      </div>
    </div>

    <footer className="footer">
    <div className="footer-links">
      <a href="/contact">Contact</a>
      <a href="/privacy">Privacy Policy</a>
      <a href="/terms">Terms & Conditions</a>
      <a href="/faq">FAQ</a>
      <a href="/about">About</a>
    </div>
  </footer>
    </>
  );
};

export default AboutPage;
