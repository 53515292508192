import React, { useState, useEffect, useRef } from "react";
import "./QuotePage.css";


const services = [
  { name: "Long-Distance Moves", description: "Seamless transitions across miles." },
  { name: "Local Residential Moves", description: "Moving solutions within your city." },
  { name: "Hourly Moving Assistance", description: "Two movers for $300 for 2 hours." },
  { name: "Packing & Unpacking", description: "Convenient packing and unpacking services." },
  { name: "Assembly | Disassembly", description: "Furniture assembly and disassembly." },
  { name: "Commercial Moves", description: "Efficient business relocations." },
  { name: "Small Moves", description: "Compact and single-item moves." },
];

const items = [
    { name: "Sofa | Loveseat", cubicFeet: 30  },
    { name: "Sofa | 3 Cushion", cubicFeet: 35 },
    { name: "Sofa | 4 Cushion | Hide", cubicFeet: 50 },
    { name: "Stereo Component", cubicFeet: 60},
    { name: "Stereo | Console", cubicFeet: 8},
    { name: "Tables | Drop Leaf", cubicFeet: 15 },
    { name: "Tables | Occasional", cubicFeet: 15},
    { name: "Tables | Coffee", cubicFeet: 12 },
    { name: "Tables | End", cubicFeet: 5 },
    { name: "Telephone Stand", cubicFeet: 5 },
    { name: "TV | Big Screen", cubicFeet: 40},
    { name: "TV | Portable", cubicFeet: 5},
    { name: "TV | Table Model", cubicFeet: 10},
    { name: "TV | Console", cubicFeet: 15},
    { name: "TV | Combination", cubicFeet: 25},
    { name: "TV | Stand", cubicFeet: 3},
    { name: "Trunk", cubicFeet: 5},
    { name: "Planters | Small", cubicFeet: 1 },
    { name: "Planters | Medium", cubicFeet: 1.5 },
    { name: "Bed Inc. Sp/Matt", cubicFeet: 40 },
    { name: "Bed | Rollaway", cubicFeet: 20},
    { name: "Bed | Single", cubicFeet: 40 },
    { name: "Bed | Std/Dbl.", cubicFeet: 60},
    { name: "Bedframe | Queen", cubicFeet: 65},
    { name: "Bedframe | King", cubicFeet: 70},
    { name: "Bedframe | Bunk (Set 2)", cubicFeet: 70},
    { name: "Bookshelves | Sect.", cubicFeet: 5},
    { name: "Chair | Boudoir", cubicFeet: 10},
    { name: "Chair | Rocker", cubicFeet: 5},
    { name: "Chaise Lounge", cubicFeet: 25},
    { name: "Chest | Bachelor", cubicFeet: 12 },
    { name: "Chest | Cedar", cubicFeet: 15 },
    { name: "Chest | Armoire", cubicFeet: 30 },
    { name: "Desk, SM/Winthrop", cubicFeet: 22 },
    { name: "Dresser | Vanity", cubicFeet: 20},
    { name: "Dresser | Single", cubicFeet: 30 },
    { name: "Dresser | Double", cubicFeet: 40},
    { name: "Dresser | Triple", cubicFeet: 50},
    { name: "Exercise Bike", cubicFeet: 10 },
    { name: "Peloton Bike", cubicFeet: 41.62 },
    { name: "Lamp | Floor", cubicFeet: 3 },
    { name: "Table | Night", cubicFeet: 5 },
    { name: "Rug | Pad : Small", cubicFeet: 3  },
    { name: "Rug | Pad : Large", cubicFeet: 10 },
    { name: "Wardrobe | Small", cubicFeet: 20, },
    { name: "Wardrobe | Large", cubicFeet: 40, },
    { name: "Bed | Water", cubicFeet: 20 , },
    { name: "Ironing Board", cubicFeet: 2,  },
    { name: "Bassinette", cubicFeet: 5 },
    { name: "Bed | Youth", cubicFeet: 30 },
    { name: "Chair | Child's", cubicFeet: 3 },
    { name: "Chair | High", cubicFeet: 5},
    { name: "Chest", cubicFeet: 12 },
    { name: "Chest | Toy", cubicFeet: 5},
    { name: "Crib | Baby", cubicFeet: 10 },
    { name: "Table | Child's", cubicFeet: 5 },
    { name: "Playpen", cubicFeet: 10 },
    { name: "Buffer (Base)", cubicFeet: 30 },
    { name: "Hutch (Top)", cubicFeet: 20 },
    { name: "Cabinet, Corner", cubicFeet: 20},
    { name: "Dining Table", cubicFeet: 30 },
    { name: "Dining Chair", cubicFeet: 5},
    { name: "Server", cubicFeet: 15},
    { name: "Tea Cart", cubicFeet: 10 },
    { name: "Rugs | Pads : Small", cubicFeet: 3 },
    { name: "Rugs | Pads : Large", cubicFeet: 10},
    { name: "Kitchen Chairs", cubicFeet: 5 },
    { name: "Kitchen Table", cubicFeet: 10 },
    { name: "Kitchen Cabinet", cubicFeet: 3 },
    { name: "Microwave Oven", cubicFeet: 10 },
    { name: "Serving Cart", cubicFeet: 15},
    { name: "Stool", cubicFeet: 3 },
    { name: "Utility Cabinet", cubicFeet: 10 },
    { name: "Air Cond./Wind. Small", cubicFeet: 15 },
    { name: "Air Cond./Wind. Large", cubicFeet: 20 },
    { name: "Dehumidifier", cubicFeet: 10 },
    { name: "Dishwasher", cubicFeet: 20 },
    { name: "Freezer 10 cu ft or less", cubicFeet: 30 },
    { name: "Freezer 11 to 15 cu ft", cubicFeet: 45 },
    { name: "Freezer 16 cu ft or over", cubicFeet: 60},
    { name: "Range 20'' Wide", cubicFeet: 10 },
    { name: "Range 30'' Wide", cubicFeet: 15 },
    { name: "Range 36'' Wide", cubicFeet: 30 },
    { name: "Refrigerator 6 cu ft or less", cubicFeet: 30},
    { name: "Refrigerator 7 to 10 cu ft", cubicFeet: 45 },
    { name: "Refrigerator 11 cu ft or over", cubicFeet: 60 },
    { name: "Trash Compactor", cubicFeet: 15 },
    { name: "Vacuum Cleaner", cubicFeet: 5 },
    { name: "Washing Machine", cubicFeet: 25 },
    { name: "Dryer", cubicFeet: 25 },
    { name: "BBQ Grill, Camping", cubicFeet: 2 },
    { name: "BBQ Grill, Large", cubicFeet: 10 },
    { name: "Wastepaper Basket", cubicFeet: 2 },
    { name: "Work Bench", cubicFeet: 20},
    { name: "Book Box", cubicFeet: 2 },
    { name: "Medium Box", cubicFeet: 3 },
    { name: "Large Box", cubicFeet: 6},
    { name: "Dish Pack", cubicFeet: 10},
    { name: "Wardrobe Box", cubicFeet: 16},
    { name: "Headboard | Twin ", cubicFeet: 7 },
    { name: "Headboard | Full/Double ", cubicFeet: 10 },
    { name: "Headboard | Queen ", cubicFeet: 15 },
    { name: "Headboard | King ", cubicFeet: 20 },
    { name: "Headboard | California King", cubicFeet: 22 },
    { name: "Mattress | Twin", cubicFeet: 20 },
    { name: "Mattress | Full/Double", cubicFeet: 30 },
    { name: "Mattress | King", cubicFeet: 55 },
    { name: "Mattress | Queen", cubicFeet: 50 },
    { name: "Mattress | California King", cubicFeet: 60 },
    { name: "Night Stand | Table", cubicFeet: 8 },
    { name: "Small Print | framed wall art or posters", cubicFeet: 1.5 },
    { name: "Medium Print | framed wall art or posters", cubicFeet: 3 },
    { name: "Large Print | framed wall art or posters", cubicFeet: 6 },
    { name: "X-Large Print | framed wall art or posters", cubicFeet: 10 },
    { name: "Fan| Tower", cubicFeet: 4 },
    { name: "Fan| Pedestal", cubicFeet: 6.75 },
    { name: "Fan| Box", cubicFeet: 3.125 },
    { name: "Fan| Wall", cubicFeet: 1.5 },
    { name: "Fan| Ceiling", cubicFeet: 4.5 },
    { name: "Fan| Desk", cubicFeet: 3.375 },
    { name: "Shelving", cubicFeet: 6 },
    { name: "Shoe Rack", cubicFeet: 12 },
    { name: "Shoe Rack | Double", cubicFeet: 24 },
    { name: "Bags | Small", cubicFeet: 5 },
    { name: "Bags | Medium", cubicFeet: 7 },
    { name: "Bags | Large", cubicFeet: 12 },
    { name: "Bags | Extra Large", cubicFeet: 24 },
    { name: "Mirror | Small Flat", cubicFeet: 0.75 },
    { name: "Mirror | Medium |Flat|Framed", cubicFeet: 1.8 },
    { name: "Mirror | Large Wall ", cubicFeet: 1.5 },
    { name: "Mirror | Boxed | Large", cubicFeet: 12.0 },
    { name: "Mirror | Framed | Square | Small", cubicFeet: 2.7 },
    { name: "Mirror | Framed| Small", cubicFeet: 2.25 },
    { name: "Bookcase", cubicFeet: 7.8 },
    { name: "Bookcase | Double", cubicFeet: 15.6 },


    

    


];
const supplies = [
  { name: "Box Small/Book 1.5cf", price: 2.0 },
  { name: "Box Medium/Linen 3.0cf", price: 3.0 },
  { name: "Box Large/China 5.0cf", price: 6.5 },
  { name: "Box Wardrobe", price: 22.0 },
  { name: "White Paper 10lbs", price: 18.0 },
  { name: "Roll of Tape", price: 4.0 },
  { name: "Mattress Cover Multisize", price: 18.0 },
];

const QuotePage = () => {
  
  const [messages, setMessages] = useState([
    { sender: "bot", text: "Welcome to Sophisticated Moves! Let’s get started with your moving quote. What's your name?" },
  ]);

  const [step, setStep] = useState(0);
  const [userInput, setUserInput] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    pickupAddress: "",
    access: "",
    dropoffAddress: "",
    accessDropoff: "",
    bedroom_multipliers: "",
    moveType: "",
    moveDate: "", // Expected format: mm-dd-YYYY,
    moveTime: "",
    mileage: [],
    flightsOfStairs: 0,
    packingService: false,
    unpackingService: false,
    itemsToAssemble: 0,
    inventory: [],
    supplies: [],
    totalCubicFeet: 0,
    estimatedTotal: 0,
    additionalMessage: "",
  });

  const [searchQuery, setSearchQuery] = useState(""); // For the search bar
  const [selectedItem, setSelectedItem] = useState(""); // For dropdown selection
  const [quantity, setQuantity] = useState(1); // Quantity input
  const messagesEndRef = useRef(null);

  const isValidDate = (dateStr) => {
    // Regex for MM-DD-YYYY
    const regex = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/;
  
    // Test the format
    if (!regex.test(dateStr)) {
      return false;
    }
  
    // Validate the date
    const [month, day, year] = dateStr.split("-").map((part) => parseInt(part, 10));
    const date = new Date(year, month - 1, day);
    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
  };
  
    console.log(isValidDate("01/21/2025")); // true
    console.log(isValidDate("02/30/2025")); // false
    console.log(isValidDate("13/01/2025")); // false
    console.log(isValidDate("12/15/abcd")); // false
    

  

  

  const steps = [
    { field: "fullName", prompt: "What is your full name?", response: (input) => `Nice to meet you, ${input}. What's your email address?` },
    { field: "email", prompt: "What is your email address?", response: (input) => `Thanks! What's your phone number?` },
    { field: "phoneNumber", prompt: "What is your phone number?", response: (input) => `Got it. What service are you looking for?` },
    { field: "serviceType", prompt: "Choose a service:", isServiceStep: true },
    { field: "moveType", prompt: "Have questions about the services, please enter them here.", response: (input) => `Great, ${input} it is. How many bedrooms are in your home?` },
    { field: "bedroom_multipliers", prompt: "e.g. Studio, 1 Bedroom, 2 Bedrooms, 3 Bedrooms 4+ Bedrooms", response: (input) => `What's the pickup address(e.g. 123 Main St, City, State, Zip, Apt|Ste #?` },
    { field: "pickupAddress", prompt: "Pickup Location (e.g. 123 Main St, City, State, Zip)?", response: (input) => `Thanks! Please provide the type of access Elevator, Ground Floor, Stairs?` },
    { access: "access", prompt: "Access: Elevator, Stairs, Ground Floor ", response: (input) => `If you have stairs, how many flight of stairs? if none put 0` },
    { field: "flightsofStairs", prompt: "How many flights of stairs? if none put 0", response: (input) => `Thanks! What's the drop-off address(e.g. 123 Main St, City, State, Zip, Apt|Ste #?` },
    { field: "dropOffAddress", prompt: "Drop-off Address?", response: (input) => `Thanks! Please provide the type of access Elevator, Ground Floor, Stairs?` },
    { access: "accessDropoff", prompt: "Access: Elevator, Stairs, Ground Floor ", response: (input) => `How many miles is the move (from pickup - dropoff)?` },
    { field: "mileage", prompt: "How far is the move (miles)?", response: (input) => `Got it. ${input} miles. Please enter your move date (MM-DD-YYYY).` },
    { field: "moveDate", prompt: "MM-DD-YYYY (e.g., 01-21-2025", response: (input) => `Your move date is set to ${input}. What's the move time (HH:MM)?`  },
    { field: "moveTime", prompt: "What is your move time? (e.g. 10:00 AM)", response: (input) => `Your move time is set to ${input}. Do you require packing services? (Yes or No)` },
    { field: "packingService", prompt: "Do you require packing services?", response: (input) => `Packing services ${input ? "added" : "skipped"}. How about unpacking services?` },
    { field: "unpackingService", prompt: "Do you require unpacking services?", response: (input) => `Unpacking services ${input ? "added" : "skipped"}. Any items that need assembly or disassembly?` },
    { field: "itemsToAssemble", prompt: "How many items need assembly/disassembly?", response: (input) => `Got it. Let's move to your inventory.` },
    { field: "inventory", prompt: "Select items for your inventory:", isInventoryStep: true },
    { 
      field: "purchaseSupplies", 
      prompt: "Do you need to purchase supplies for your move? (Yes or No)", 
      response: (input) => input.toLowerCase() === "yes" 
        ? `Great! Let's select supplies.` 
        : `No problem! We'll skip the supplies.`,
      handleResponse: (input, setFormData, addMessage, setStep) => {
        const isYes = input.toLowerCase() === "yes";
        setFormData((prev) => ({ ...prev, purchaseSupplies: isYes }));
        addMessage("user", isYes ? "Yes" : "No");
        if (isYes) setStep((prevStep) => prevStep + 1); // Proceed to supplies step
        else setStep((prevStep) => prevStep + 1); // Skip supplies step
      }
    },
    { field: "supplies", prompt: "Select supplies to purchase for your move:", isSuppliesStep: true },
    { field: "additionalMessage", prompt: "Any additional comments or questions?", response: (input) => `Thanks for letting us know: "${input}".` },
    { field: "estimatedTotal", prompt: "What's the estimated total of your move?", response: (input) => `Got it. ${input} is the estimated total.` },
    { field: "finalMessage", prompt: "Thank you for your time. We will contact you shortly.", isFinalStep: true },
  ];
  

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const addMessage = (sender, text) => {
    setMessages((prev) => [...prev, { sender, text }]);
  };

  
  

  const handleUserResponse = () => {
    const currentStep = steps[step];
    const input = userInput.trim();
  
    if (!input) {
      addMessage("bot", "Please provide a valid response.");
      return;
    }
  
    // Validate specific fields
    if (currentStep.field === "mileage" || currentStep.field === "flightsOfStairs") {
      if (isNaN(input) || Number(input) < 0) {
        addMessage(
          "bot",
          currentStep.field === "mileage"
            ? "Please enter a valid mileage (a non-negative number)."
            : "Please enter a valid number for flights of stairs (0 or more)."
        );
        return;
      }
    }
  
    if (currentStep.field === "moveDate" && !isValidDate(input)) {
      addMessage("bot", "The date you entered is invalid. Please format the date as MM-DD-YYYY (e.g., 01-21-2025).");
      return;
    }
    
  
    // Update formData with the valid input
    setFormData((prev) => ({
      ...prev,
      [currentStep.field]: currentStep.field === "mileage" || currentStep.field === "flightsOfStairs" ? Number(input) : input,
    }));
  
    addMessage("user", input);
    addMessage("bot", currentStep.response(input));
    setUserInput("");
    setStep((prev) => prev + 1);
  };
  
  

  const handleServiceSelection = (serviceName) => {
    setFormData({ ...formData, serviceType: serviceName });
    addMessage("user", ` ${serviceName}`);
    setStep(step + 1);
  };

  const handleAddInventoryItem = () => {
    const item = items.find((i) => i.name === selectedItem);
    if (!item || quantity <= 0) {
      alert("Please select a valid item and quantity.");
      return;
    }

    const existingItem = formData.inventory.find((inv) => inv.name === item.name);
    const updatedInventory = existingItem
      ? formData.inventory.map((inv) =>
          inv.name === item.name ? { ...inv, quantity: inv.quantity + quantity } : inv
        )
      : [...formData.inventory, { ...item, quantity }];

    setFormData({
      ...formData,
      inventory: updatedInventory,
      totalCubicFeet: formData.totalCubicFeet + item.cubicFeet * quantity,
    });

    addMessage("bot", `${quantity} x ${item.name} to inventory.`);
    setSelectedItem("");
    setQuantity(1);
  };

  const submitInventory = () => {
    if (formData.inventory.length === 0) {
      addMessage("bot", "Please select at least one item.");
      return;
    }
    addMessage("bot", "Inventory submitted.");
    setStep(step + 1);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
        if (steps[step]?.isServiceStep) {
            // Handle service selection
            const service = services.find((s) => s.name.toLowerCase().includes(userInput.toLowerCase()));
            if (service) {
                handleServiceSelection(service.name);
            } else {
                addMessage("bot", "Please select a valid service.");
            }
        } else if (steps[step]?.isInventoryStep || steps[step]?.isSuppliesStep) {
            // Handle inventory or supplies submission
            handleAddInventoryItem();
        } else {
            // Handle regular text input submission
            handleUserResponse();
        }
    }
};


  // const [showSupplies, setShowSupplies] = useState(true); // State to control visibility

  const finalizeQuote = async () => {
    // Calculate assembly/disassembly cost
    const assemblyCost = formData.itemsToAssemble * 135;

    // Calculate supplies total
    const suppliesTotal = formData.supplies.reduce(
        (total, supply) => total + supply.price * supply.quantity,
        0
    );

    // Calculate inventory cost (assume $1 per cubic foot for simplicity)
    const inventoryCost = formData.totalCubicFeet;

    // Calculate mileage cost ($2 per mile)
    const mileageCost = formData.mileage * 2;

    // Calculate stairs cost ($25 per flight of stairs)
    const stairsCost = formData.flightsOfStairs * 25;

    // Calculate estimated total
    const estimatedTotal = inventoryCost + suppliesTotal + mileageCost + stairsCost + assemblyCost;

    // Update formData with calculated totals
    const updatedFormData = {
        ...formData,
        estimatedTotal,
        assemblyCost,
    };

    try {
        const response = await fetch("http://127.0.0.1:5000/send-quote", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedFormData),
        });

        const result = await response.json();
        if (result.status === "success") {
            addMessage("bot", `Your estimated total is $${estimatedTotal.toFixed(2)}. Your quote has been sent! Check your email for confirmation.`);
            // Add a short delay before refreshing the page
            setTimeout(() => {
                window.location.reload(); // Refresh the page
            }, 3000); // 3-second delay
        } else {
            addMessage("bot", `There was an error sending your quote: ${result.message}`);
        }
    } catch (error) {
        addMessage("bot", "An error occurred. Please try again later.");
    }
};

  return (
  
      <>
      <div className="quote-container">
      <div className="quote-description">
        
        <strong>Your Sophisticated Move Starts Here <br /> Get Your Free Quote Now!</strong>
        <p>
        Quick and personalized moving quote on your schedule. <br/>
        Let us know your moving details, and we’ll provide an estimate tailored to your needs. <br>
      </br> 
        Start now by entering your information below!
        </p>
      </div>
      
      <div className="quote-body">
  
        <div className="quote-page">
          
          <div className="chatbot-container">
            
            <div className="chatbot-messages">
              {messages.map((message, index) => (
                <div key={index} className={`message ${message.sender}`}>
                  {message.sender === 'user' && (
                    <img src="./assistant.gif" alt="Bot" className="avatar" />
                  )}
                  {message.sender === 'bot' && (
                    <img src="../icon1.gif" alt="User" className="avatar" />
                  )}
                  <p>{message.text}</p>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
    
            <div className="chatbot-input">
              {steps[step]?.isServiceStep && (
                <div className="service-options">
                  <h3>Select a Service</h3>
                  {services.map((service) => (
                    <button key={service.name} onClick={() => handleServiceSelection(service.name)}>
                      {service.name}
                    </button>
                  ))}
                </div>
              )}
    
              {steps[step]?.isInventoryStep && (
                <div className="inventory-options">
                  <h3>Select Items from Inventory Checklist</h3>
                  <input
                    type="text"
                    placeholder="Search items..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                  />
                  
                  <select
                    className="inventory-dropdown"
                    value={selectedItem}
                    onChange={(e) => setSelectedItem(e.target.value)}
                  >
                    <option value="">-- Select an Item --</option>
                    {items
                      .filter((item) =>
                        item.name.toLowerCase().includes(searchQuery)
                      )
                      .map((item) => (
                        <option key={item.name} value={item.name}>
                          {item.name} ({item.cubicFeet} CF)
                        </option>
                      ))}
                  </select>
    
                  <div className="quantity-input">
                    <label>Qty:</label>
                    <input
                      type="number"
                      min="0"
                      value={quantity}
                      onChange={(e) => setQuantity(parseInt(e.target.value) || 1)}
                    />
                  </div>
    
                  <button onClick={handleAddInventoryItem}>Add to Inventory</button>
                  
                  {formData.inventory.length > 0 && (
                    <div className="selected-inventory">
                      <h4>Selected Items:</h4>
                      <ul>
                        {formData.inventory.map((item, index) => (
                          <li key={index}>
                            {item.name} ({item.cubicFeet} CF) - Qty: {item.quantity}
                            <button
                              style={{
                                marginLeft: "10px",
                                backgroundColor: "#d4af37",
                                color: "black",
                                border: "none",
                                padding: "5px",
                                cursor: "pointer",
                                borderRadius: "5px",
                              }}
                              onClick={() =>
                                setFormData((prev) => ({
                                  ...prev,
                                  inventory: prev.inventory.filter((_, i) => i !== index),
                                  totalCubicFeet: prev.totalCubicFeet - item.cubicFeet * item.quantity,
                                }))
                              }
                            >
                              Remove
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <button onClick={submitInventory}>Submit Inventory</button>
                </div>
              )}
    
              {steps[step]?.isSuppliesStep && (
                <div className="supplies-options">
                  <h3>Select Supplies</h3>
                  <ul>
                    {supplies.map((supply) => (
                      <li key={supply.name} style={{ marginBottom: "10px" }}>
                        {supply.name} (${supply.price}) 
                        <input
                          type="number"
                          min="1"
                          placeholder="Qty"
                          value="0"
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              supplies: prev.supplies.map((s) =>
                                s.name === supply.name
                                  ? { ...s, quantity: parseInt(e.target.value) || 1 }
                                  : s
                              ),
                            }))
                          }
                          style={{
                            marginLeft: "10px",
                            width: "50px",
                            textAlign: "center",
                          }}
                        />
                        <button
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            const existingSupply = formData.supplies.find((s) => s.name === supply.name);
                            if (existingSupply) {
                              setFormData((prev) => ({
                                ...prev,
                                supplies: prev.supplies.map((s) =>
                                  s.name === supply.name
                                    ? { ...s, quantity: s.quantity + 1 }
                                    : s
                                ),
                              }));
                            } else {
                              setFormData((prev) => ({
                                ...prev,
                                supplies: [...prev.supplies, { ...supply, quantity: 1 }],
                              }));
                            }
                            addMessage("bot", `Added ${supply.name} to your supplies.`);
                          }}
                        >
                          Add
                        </button>
                      </li>
                    ))}
                  </ul>
                  <button onClick={finalizeQuote}>Finalize Quote</button>
                </div>
              )}
    
              {steps[step]?.isQuoteSummaryStep && (
                <div className="quote-summary">
                  <h3>Quote Summary</h3>
                  <div className="quote-details">
                    <p><strong>Name:</strong> {formData.fullName}</p>
                    <p><strong>Email:</strong> {formData.email}</p>
                    <p><strong>Phone:</strong> {formData.phoneNumber}</p>
                    <p><strong>Service Type:</strong> {formData.serviceType}</p>
                    <p><strong>Inventory Total:</strong> {formData.totalCubicFeet} CF</p>
                    <p><strong>Supplies Total:</strong> ${formData.supplies.reduce((total, supply) => total + supply.price * supply.quantity, 0).toFixed(2)}</p>
                    <p><strong>Supplies Total:</strong> ${formData.supplies.reduce((total, supply) => total + supply.price * supply.quantity, 0).toFixed(2)}</p>
                    <p><strong>Assembly Cost:</strong> ${formData.assemblyCost || 0}</p>
                    <p><strong>Estimated Total:</strong> ${formData.estimatedTotal.toFixed(2)}</p>
                  </div>
                  <button
                    className="finalize-button"
                    onClick={finalizeQuote}
                    onKeyPress={(e) => {
                    if (e.key === "Enter") finalizeQuote();
                    }}
                    >
                    Finalize Quote
                    </button>

                </div>
              )}
    
              {!steps[step]?.isServiceStep && !steps[step]?.isInventoryStep && !steps[step]?.isSuppliesStep && (
                <>
                  <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder={steps[step]?.prompt || "Type your message..."}
                    onKeyPress={handleKeyPress} // Handle Enter key

                  />
                  <button onClick={handleUserResponse}>Send</button>
                </>
              )}
              {/* Add the notice at the bottom */}
          <div className="quote-notice">
                  <p>
                  <strong>Note:</strong> The price provided is an estimate and not final. 
                    We offer price matching and the final quote is determined based on the 
                    accuracy of the items listed. Please ensure all items are included for 
                    an accurate quote.
                    </p>
                </div>
            </div>
          </div>
          
        </div>
        </div>
        </div>
        
    
        <footer className="footer">
          <p>&copy; 2022 Sophisticated Moves. All rights reserved.</p>
          <div className="footer-links">
            <a href="/contact">Contact</a>
            <a href="#privacy">Privacy Policy</a>
            <a href="#terms">Terms & Conditions</a>
            <a href="#faq">FAQ</a>
            <a href="/about">About</a>
          </div>
        </footer>
  
      </>
    );
    
};

export default QuotePage;