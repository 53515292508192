import React from "react";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
    return (
    <div className="terms-container">
        <h1>📜 Terms & Conditions</h1>
        <p>Last Updated: January 2025</p>

        <section>
        <h2>1. Introduction</h2>
        <p>
            Welcome to Sophisticated Moves! These terms govern your use of our services. By booking with us, you agree to follow these terms.
        </p>
        </section>

        <section>
        <h2>2. Booking & Payments</h2>
        <p>
            - All bookings require a deposit to confirm your reservation. <br />
            - Payments are accepted via credit/debit card, cash, or other approved methods. <br />
            - Cancellations must be made at least 48 hours in advance for a full refund. <br />
        </p>
        </section>

        <section>
        <h2>3. Liability & Damages</h2>
        <p>
            - Sophisticated Moves is not liable for damages caused by improper packing of items not handled by our team. <br />
            - Clients must report any damages within 24 hours of the move. <br />
            - We are not responsible for delays caused by weather, road conditions, or unforeseen circumstances. <br />
        </p>
        </section>

        <section>
        <h2>4. Privacy & Data</h2>
        <p>
            - We collect personal data only for booking and service-related purposes. <br />
            - Your information will not be shared or sold to third parties. <br />
        </p>
        </section>

        <section>
        <h2>5. Amendments</h2>
        <p>
            - These terms may be updated at any time. Continued use of our services constitutes agreement to updated terms.
        </p>
        </section>

        <p>
        If you have any questions, please contact us at: <a href="mailto:admin@sophisticatedmoves.com">admin@sophisticatedmoves.com</a>
        </p>
    </div>
    );
};

export default TermsAndConditions;
