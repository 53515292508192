import React, { useState } from "react";
import "./FAQ.css";

const faqs = [
  {
    question: "What services does Sophisticated Moves offer?",
    answer:
      "We offer local and long-distance moves, hourly moving assistance, packing/unpacking, furniture assembly/disassembly, and commercial moves.",
  },
  {
    question: "How do I get a free moving quote?",
    answer:
      "You can request a free quote by using our chatbot or filling out the quote request form on our website.",
  },
  {
    question: "Are there any hidden fees?",
    answer:
      "No, we believe in transparent pricing. Your quote includes all moving costs, and there are no hidden fees.",
  },
  {
    question: "What payment methods do you accept?",
    answer:
      "We accept credit/debit cards, cash, and other approved payment methods. A deposit may be required to secure your booking.",
  },
  {
    question: "How can I reschedule or cancel my move?",
    answer:
      "Contact us at least 48 hours before your scheduled move to reschedule or cancel. Cancellations made within 48 hours may incur a fee.",
  },
  {
    question: "Are my belongings insured during the move?",
    answer:
      "Yes, we provide basic coverage for all moves. Additional insurance options are available upon request.",
  },
  {
    question: "Do you provide packing supplies?",
    answer:
      "Yes! We offer high-quality moving supplies, including boxes, tape, and protective packing materials.",
  },
  {
    question: "How do I prepare for moving day?",
    answer:
      "Ensure all loose items are packed, label boxes for easy unpacking, and secure parking for the moving truck if needed.",
  },
  {
    question: "Do you move specialty items like pianos or antiques?",
    answer:
      "Yes, we specialize in handling fragile and oversized items with extra care. Let us know in advance so we can prepare accordingly.",
  },
  {
    question: "How far in advance should I book my move?",
    answer:
      "We recommend booking at least 2-3 weeks in advance for local moves and 4-6 weeks for long-distance moves.",
  },
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h1>❓ Frequently Asked Questions</h1>
      <p className="faq-intro">
        Find answers to common questions about our moving services. If you need
        more help, feel free to <a href="/contact">contact us</a>.
      </p>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? "active" : ""}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              <span>{faq.question}</span>
              <span className="faq-icon">{activeIndex === index ? "➖" : "➕"}</span>
            </div>
            {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
