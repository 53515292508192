import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaTimes, FaSearch } from "react-icons/fa";
import "./Navbar.css";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  // Handle Search
  const handleSearch = (e) => {
    e.preventDefault();
    const pageMapping = {
      home: "/",
      about: "/about",
      services: "/services",
      quote: "/quote",
      contact: "/contact",
    };

    const searchKey = searchQuery.toLowerCase();
    const matchedPage = Object.keys(pageMapping).find((key) =>
      searchKey.includes(key)
    );

    if (matchedPage) {
      navigate(pageMapping[matchedPage]);
    } else {
      alert("No matching pages found.");
    }

    setSearchQuery("");
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          {/* Logo */}
          <Link to="/" className="navbar-logo">
            <img src="./SMNYLOGO1.png" alt="Logo" className="logo" />
          </Link>

          {/* Hamburger Menu for Mobile */}
          <div className="hamburger" onClick={() => setIsMobile(!isMobile)}>
            {isMobile ? <FaTimes className="menu-icon" /> : <FaBars className="menu-icon" />}
          </div>

          {/* Navigation Links */}
          <ul className={isMobile ? "nav-links-mobile" : "nav-links"} onClick={() => setIsMobile(false)}>
            <li><Link to="/" className="nav-link">Home</Link></li>
            <li><Link to="/services" className="nav-link">Services</Link></li>
            <li><Link to="/quote" className="nav-link">Quote</Link></li>
            <li><Link to="/contact" className="nav-link">Contact</Link></li>
            <li><Link to="/about" className="nav-link">About</Link></li>
          </ul>

          {/* Search Bar */}
          <form className="search-bar" onSubmit={handleSearch}>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button type="submit" className="search-btn">
              <FaSearch />
              <span>Search</span>
            </button>
          </form>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
